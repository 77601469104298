import { NextPage } from 'next'
import { SEO, IconNotFound, Button } from 'components'

const PageError: NextPage = () => (
  <>
    <SEO
      title="Falha ao carregar página"
      tabName="Falha ao carregar página"
      description="Ops! Desculpa, mas sua página não foi encontrada!"
    />
    <section className="flex flex-col-reverse sm:flex-row items-center gap-x-10 justify-center pt-[5vh] sm:pt-[10vh] pb-10 px-4 my-auto">
      <div className="flex flex-col gap-y-6 items-center text-center">
        <h1 className="font-black text-[120px] sm:text-[180px] lg:text-[200px] text-primary leading-none">
          404
        </h1>

        <div className="space-y-2 text-gray-600 max-w-[490px] text-body1 sm:text-sm font-medium">
          <p>Ops! Desculpe, essa página não foi encontrada!</p>

          <p>
            Caso ela tenha sido criada recentemente, aguarde alguns instantes
            até que ela seja sincronizada.
          </p>
        </div>

        <Button href="/" className="mt-4">
          Página inicial
        </Button>
      </div>

      <div className="w-[220px] sm:w-[300px] lg:w-[414px] h-auto">
        <IconNotFound />
      </div>
    </section>
  </>
)

export default PageError
